<template>
  <div class="container py-5">
    <p class="fw-bold">第27屆CBE中國美容博覽會</p>
    
    <p>時間：2023 05/12-14</p>
    <p>地點：上海新國際博覽中心</p>
    <p>展位：E4C05</p>
    <p>
     第27屆美博會將於5月12日-14日在上海新國際博覽中心舉辦。屆時，匯聚來自全球超過 40個國家及地區的 3200+ 美妝企業，10000+ 美妝品牌，60000+ 美妝產品，100+場特別活動……將在260000平方米的全館面積裡精彩上演。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_24.jpg"
    />

  </div>
</template>
<script>
export default {};
</script>
